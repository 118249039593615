import { AfterContentInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth';
import { CanvasService, ClientService, RuleViolationService } from '@app/core';
import { DevFlagService } from '@app/core/dev-flag.service';
import { Client } from '@app/core/store/client.store';
import { NotificationObject, NotificationType } from '@app/shared/notification/notificationObject';

@Component({
  selector: 'component-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.sass'],
})
export class TopNavigationComponent implements AfterContentInit, OnInit {
  user: any;
  clients: Client[] | undefined;
  selectedClient: Client | undefined;
  menuLinks = [
    {
      name: 'Create',
      url: '/create',
      adminRequired: false,
    },
    {
      name: 'Manage',
      url: '/manage',
      adminRequired: false,
    },
    {
      name: 'Snapshot',
      url: '/snapshot',
      adminRequired: true,
    },
  ];

  show = false;
  activatedBy;
  ruleViolation = false;

  isAdmin = false;

  notification = new NotificationObject();
  notificationType = NotificationType;
  v4Enabled = false;

  constructor(
    private auth: AuthService,
    private ruleViolationService: RuleViolationService,
    private devFlagService: DevFlagService,
    private clientService: ClientService,
    private canvasService: CanvasService,
    private route: ActivatedRoute,
    public router: Router,
  ) {
    this.ruleViolationService.ruleViolation$.subscribe((violation) => {
      if (violation) {
        this.ruleViolation = violation;
      } else {
        this.ruleViolation = false;
      }
    });

    if (this.auth.getRole() === 'admin') {
      this.isAdmin = true;
    }

    if (this.isAdmin) {
      this.menuLinks.push({
        name: 'Widgets',
        url: '/widgets',
        adminRequired: false,
      });
    }

    if (this.devFlagService.isFlagEnabled('v4')) {
      this.v4Enabled = true;
      this.menuLinks.splice(1, 0, {
        name: 'Looks',
        url: '/looks',
        adminRequired: false,
      });
    }

    if (this.devFlagService.isFlagEnabled('v4')) {
      this.v4Enabled = true;
      this.menuLinks.splice(1, 0, {
        name: 'Products',
        url: '/products',
        adminRequired: false,
      });
    }
  }

  ngOnInit() {
    if (this.isAdmin) {
      this.clientService.getAllClients().subscribe((clients) => {
        this.clients = clients;
      });
    } else {
      this.clientService.getUserClients().subscribe((clients) => {
        this.clients = clients;
      });
    }
    this.clientService.getSelectedClient().subscribe((client) => {
      this.selectedClient = client;
    });
  }

  ngAfterContentInit() {
    this.auth.userProfile$.subscribe((response) => {
      this.user = response;
    });
  }

  logout(): void {
    this.auth.logout();
  }

  showModal(event: Event): void {
    this.activatedBy = event.target;
    this.show = !this.show;
  }

  handleClientChange(event: Event) {
    const client = this.clients?.find((c) => c.uid === (event.target as HTMLSelectElement).value);
    const previousclient = this.selectedClient;
    if (!client || previousclient?.uid === client.uid) {
      return;
    }
    this.clientService.setSelectedClient(client);
    this.canvasService.sendToCanvas(false);
    localStorage.removeItem('lookToEdit');
    // If URL contains Look or Item UID, navigate to parent route
    if (this.route.firstChild) {
      this.router.navigate(['.'], { relativeTo: this.route }).then(() => {
        // Reload page to get all client facets
        location.reload();
      });
    } else {
      // Reload page to get all client facets
      location.reload();
    }
  }

  preventNavigation(): void {
    this.notification.display(this.notificationType.notice, 'Please resolve the rule violation before navigating to other tabs.');
  }

  handleMenuClick(menu: (typeof this.menuLinks)[number]) {
    if (menu.name === 'Widgets') {
      // Force reload of widget page to the root url `/widgets`, in case we were on a subpage
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/widgets']);
      });
    } else if (menu.name === 'Looks') {
      // Force reload of looks page to the root url `/looks`, in case we were on a subpage
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/looks']);
      });
    } else if (menu.name === 'Products') {
      // Force reload of looks page to the root url `/products`, in case we were on a subpage
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/products']);
      });
    }
  }
}
