import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { ClientService } from './client.service';
import { CountService } from './count.service';
import { FacetService } from './facet.service';
import { ItemService } from './item.service';
import {
  AppStore,
  CachedLook,
  CachedLooksQuery,
  Category,
  CreateLookMutation,
  Facet,
  Look,
  LookMutationResponse,
  LookObj,
  LookQuery,
  LooksQuery,
  UpdateLookItemsMutation,
  UpdateLookMutation,
} from './store';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

const CTL_URL = environment.apiUrl + '/api/v3/complete-the-look';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};

export interface showCtlLook {
  show: boolean;
  itemUni?: string;
  lookUid?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LookService {
  constructor(
    private apollo: Apollo,
    private appStore: AppStore,
    private clientService: ClientService,
    private countService: CountService,
    private facetService: FacetService,
    private http: HttpClient,
    private itemService: ItemService,
  ) {}

  // Add look BehaviorSubject to send look to Canvas
  private editLook = new BehaviorSubject<Look>(null);
  editLook$ = this.editLook.asObservable();
  sendToCanvas(look: Look): void {
    this.editLook.next(look);
  }

  // Load CTL look in Create tab Canvas with CTL API response
  private ctlLook = new Subject<showCtlLook>();
  ctlLook$ = this.ctlLook.asObservable();
  publishCtlLook(show: boolean, itemUni?: string) {
    this.ctlLook.next({ show: show, itemUni: itemUni });
  }

  // Store items in application storage
  storeLooks(looks: Look[]): void {
    looks.forEach((look) => {
      look.items.forEach((item) => {
        if (look.pinnedItemUid == item.uid) {
          item.isPinned = true;
        }
        item.facets = this.facetService.formatFacets(item.facets);
        item.formattedImage = this.itemService.formatImage(item.imageUrl);
        item.shortUid = this.itemService.shortenUid(item.uid);
      });
      look.shortRootLookUid = this.shortenUid(look.rootLookUid);
    });
    this.appStore.setState('Looks', looks);
  }

  // Shorten look UID to 8-digit version
  shortenUid(lookUid: string): string {
    return lookUid.slice(0, 8);
  }

  // Used for removal; remove specified look from application storage
  removeLookFromAppStore(lookUid: string): void {
    let allLooks = this.appStore.getState('Looks');
    allLooks = allLooks.filter((look) => look.uid !== lookUid);
    this.appStore.setState('Looks', allLooks);
  }

  // Create new look
  createLook(look: Look): Observable<{ error: string; ok: string; look: Look }> {
    return this.apollo
      .mutate<any>({
        mutation: CreateLookMutation,
        variables: {
          lookFacets: {
            clientUid: look.clientUid,
            featured: look.featured,
            flagged: look.flagged,
            published: look.published,
            pinnedItemUid: look.pinnedItemUid,
          },
        },
      })
      .pipe(
        map((response) => {
          look.uid = response.data.createLook.look.uid;
          look.shortRootLookUid = this.shortenUid(response.data.createLook.look.rootLookUid);
          return {
            error: response.data.createLook.error,
            ok: response.data.createLook.ok,
            look: look,
          };
        }),
      );
  }

  // Update look facets (published, flagged, featured)
  updateLookFacets(look: Look): Observable<LookMutationResponse> {
    return this.apollo
      .mutate<any>({
        mutation: UpdateLookMutation,
        variables: {
          lookUid: look.uid,
          lookFacets: {
            featured: look.featured,
            flagged: look.flagged,
            published: look.published,
            pinnedItemUid: look.pinnedItemUid,
          },
        },
      })
      .pipe(
        map((response) => {
          response.data.updateLook.look.shortRootLookUid = this.shortenUid(response.data.updateLook.look.rootLookUid);
          return response.data.updateLook;
        }),
      );
  }

  // Update multiple look facets simultaneously (published, flagged)
  updateMultipleLookFacets(looks: Look[]): Observable<LookMutationResponse[]> {
    const lookMutations: Observable<any>[] = [];
    looks.forEach((look) =>
      lookMutations.push(
        this.apollo
          .mutate<any>({
            mutation: UpdateLookMutation,
            variables: {
              lookUid: look.uid,
              lookFacets: {
                featured: look.featured,
                flagged: look.flagged,
                published: look.published,
                pinnedItemUid: look.pinnedItemUid,
              },
            },
          })
          .pipe(
            map((response) => {
              response.data.updateLook.look.shortRootLookUid = this.shortenUid(response.data.updateLook.look.rootLookUid);
              return response.data.updateLook;
            }),
          ),
      ),
    );
    return forkJoin(lookMutations);
  }

  // Update items attributed to look
  updateLookItems(look: Look, itemUids: string[]): Observable<LookMutationResponse> {
    return this.apollo
      .mutate<any>({
        mutation: UpdateLookItemsMutation,
        variables: {
          lookUid: look.uid,
          lookItems: itemUids,
          lookFacets: {
            featured: look.featured,
            flagged: look.flagged,
            published: look.published,
            pinnedItemUid: look.pinnedItemUid,
          },
        },
      })
      .pipe(
        map((response) => {
          response.data.updateLookItems.look.shortRootLookUid = this.shortenUid(response.data.updateLookItems.look.rootLookUid);
          return response.data.updateLookItems;
        }),
      );
  }

  // Uppdate single look in application storage
  updateLookInStore(lookUid: string, rootLookUid: string): void {
    const looks = this.appStore.getState('Looks');
    const index = looks
      .map((look) => {
        return look.rootLookUid;
      })
      .indexOf(rootLookUid);
    if (index !== -1) {
      this.getLook(lookUid).subscribe((response) => {
        looks[index] = response;
        this.appStore.setState('Looks', looks);
      });
    }
  }

  // Delete look by setting live to false
  deleteLook(look: Look): Observable<LookMutationResponse> {
    return this.apollo
      .mutate<any>({
        mutation: UpdateLookMutation,
        variables: {
          lookUid: look.uid,
          lookFacets: {
            live: false,
          },
        },
      })
      .pipe(
        map((response) => {
          return response.data.updateLook;
        }),
      );
  }

  // Get single look
  getLook(lookUid: string): Observable<Look> {
    return this.apollo
      .watchQuery<any>({
        query: LookQuery,
        variables: {
          lookUid: lookUid,
        },
      })
      .valueChanges.pipe(
        map((response) => {
          if (response.data.look) {
            response.data.look.items.forEach((item, index) => {
              if (response.data.look.pinnedItemUid == item.uid) {
                item.isPinned = true;
              }
              this[index] = this.itemService.formatItem(item);
            }, response.data.look.items); // bind response.data.look.items to 'this' context
            response.data.look.shortRootLookUid = this.shortenUid(response.data.look.rootLookUid);
          }
          return response.data.look;
        }),
      );
  }

  // Get client looks
  getClientLooks(clientUid?: string): Observable<Look[]> {
    if (!clientUid) {
      this.clientService.getSelectedClient().subscribe((response) => {
        // eslint-disable-next-line no-param-reassign
        clientUid = response.uid;
      });
    }
    const lookState = this.appStore.getState('Looks');
    if (lookState) {
      return this.appStore.subscribe('Looks');
    } else {
      return this.apollo
        .watchQuery<any>({
          query: LooksQuery,
          variables: {
            clientUid: [clientUid],
            page: 1,
          },
        })
        .valueChanges.pipe(
          tap((response) => this.countService.setLookCount(response.data.looks.looksCount)),
          map((response) => {
            if (response.data.looks.looks) {
              // Format looks' item facets and shorten rootLookUid
              response.data.looks.looks.forEach((look) => {
                look.items.forEach((item, index) => {
                  if (look.pinnedItemUid == item.uid) {
                    item.isPinned = true;
                  }
                  this[index] = this.itemService.formatItem(item);
                }, look.items); // bind look.items to 'this' context
                look.shortRootLookUid = this.shortenUid(look.rootLookUid);
              });
            }
            return response.data.looks.looks;
          }),
          tap((response) => this.appStore.setState('Looks', response)),
          tap(() => this.appStore.subscribe('Looks')),
        );
    }
  }

  getCompleteTheLook(itemUni: string, appId: string): Observable<Look[]> {
    const pid = itemUni.split('||')[0];
    const color = itemUni.split('||')[1];

    const path = `?application=` + appId + `&product_id=` + pid + `&product_color_id=` + color + `&return_pdp_item=true`;

    return this.http.get(`${CTL_URL}${path}`, httpOptions).pipe(
      concatMap((ctlResponse) => {
        if (ctlResponse['result'] === 'success') {
          // Use list of Item UIDs returned in 'Order' array from CTL response to get full RunwayQL item objects
          const getFullItems = ctlResponse['looks'][0]['order'].map((itemUid) => this.itemService.getItem(itemUid));
          return combineLatest(getFullItems).pipe(
            map((fullItems: any) => {
              const ctlLook = new LookObj();
              ctlLook.items = fullItems;
              return [ctlLook];
            }),
          );
        } else {
          return Observable.of([]);
        }
      }),
      catchError((err) => {
        throw new Error(err);
      }),
    );
  }

  // "Match Looks" call used in Item Modal
  getItemLooks(clientUid: string, itemUid: string): Observable<Look[]> {
    return this.apollo
      .watchQuery<any>({
        query: LooksQuery,
        variables: {
          clientUid: [clientUid],
          itemUid: [itemUid],
        },
      })
      .valueChanges.pipe(
        map((response) => {
          // Format looks' item facets and shorten rootLookUid
          response.data.looks.looks.forEach((look) => {
            look.items.forEach((item, index) => {
              if (look.pinnedItemUid == item.uid) {
                item.isPinned = true;
              }
              this[index] = this.itemService.formatItem(item);
            }, look.items); // bind look.items to 'this' context
            look.shortRootLookUid = this.shortenUid(look.rootLookUid);
          });
          return response.data.looks.looks;
        }),
        tap((response) => this.appStore.setState('ItemLooks', response)),
        tap(() => this.appStore.subscribe('ItemLooks')),
      );
  }

  // Get CachedLooks for use in Snapshot tab
  getCachedLooks(clientUid: string): Observable<CachedLook[]> {
    return this.apollo
      .watchQuery<any>({
        query: CachedLooksQuery,
        variables: {
          clientUid: clientUid,
          page: 1,
          perPage: 20,
        },
      })
      .valueChanges.pipe(
        map((response) => {
          const unwrappedCachedLooks: CachedLook[] = [];
          response.data.cachedLooks.forEach((cachedLook) => {
            cachedLook.looks.forEach((look) => {
              look.lastUpdatedTime = cachedLook.lastUpdatedTime;
              look.displayItems.forEach((item, index) => {
                this[index] = this.itemService.formatItem(item);
                this[index].label = 'displayItem';
              }, look.displayItems);
              const additionalItems = ['pdpItem', 'replacedItem', 'replacingItem'];
              additionalItems.forEach((item) => {
                if (look[item]) {
                  look[item] = this.itemService.formatItem(look[item]);
                  look[item].label = item;
                }
              });
              look.shortRootLookUid = this.shortenUid(look.lookUid);
              // Add PDP item and display items to orderedItems array, then sort based on order array
              look.orderedItems = [look.pdpItem, ...look.displayItems];
              look.orderedItems.sort((a, b) => look.order.indexOf(a.uid) - look.order.indexOf(b.uid));
              unwrappedCachedLooks.push(look);
            });
          });
          return unwrappedCachedLooks;
        }),
      );
  }

  // Replace looks in Manage tab with those matched with item
  replaceManageLooks(): void {
    const itemLooks = this.appStore.getState('ItemLooks');
    this.appStore.setState('Looks', itemLooks);
  }

  // Filter looks
  filterLooks(
    selections: {
      authors?: Facet[];
      categories: Category[];
      editors?: Facet[];
      facets: Facet[];
    },
    page?: number,
    search?: string,
  ): Observable<Look[]> {
    let clientUid: string;
    this.clientService.getSelectedClient().subscribe((response) => {
      clientUid = response.uid;
    });

    const filters = this.formatFilters(selections);

    const qlQuery = this.apollo.watchQuery<any>({
      query: LooksQuery,
      variables: {
        authors: filters.authors,
        brand: filters.brand,
        catUids: filters.category,
        channel: filters.channel,
        clientUid: clientUid,
        collection: filters.collection,
        color: filters.color,
        editors: filters.editors,
        endUse: filters.end_use,
        fit: filters.fit,
        flag: filters.flag,
        formality: filters.formality,
        itemCount: filters.itemCount,
        page: page,
        pattern: filters.pattern,
        processed: filters.processed,
        membership: filters.membership,
        sale: filters.sale,
        size: filters.size,
        sport: filters.sport,
        status: filters.status,
        stock: filters.stock,
        textSearch: search,
        year: filters.year,
      },
    });

    let lookState = this.appStore.getState('Looks');
    if (page > 1 && lookState) {
      return qlQuery.valueChanges.pipe(
        tap((response) => this.countService.setLookCount(response.data.looks.looksCount)),
        map((response) => {
          if (response.data.looks == null) {
            lookState = lookState.concat([]);
            return lookState;
          } else {
            // this.countService.setLookCount(response.data.looks.count);
            // Format item facets and shorten rootLookUid
            response.data.looks.looks.forEach((look) => {
              look.items.forEach((item, index) => {
                if (look.pinnedItemUid == item.uid) {
                  item.isPinned = true;
                }
                this[index] = this.itemService.formatItem(item);
              }, look.items); // bind look.items to 'this' context
              look.shortRootLookUid = this.shortenUid(look.rootLookUid);
            });
            lookState = lookState.concat(response.data.looks.looks);
            return lookState;
          }
        }),
        tap((response) => this.appStore.setState('Looks', response)),
        tap(() => this.appStore.subscribe('Looks')),
      );
    } else {
      return qlQuery.valueChanges.pipe(
        tap((response) => this.countService.setLookCount(response.data.looks.looksCount)),
        map((response) => {
          if (response.data.looks.looks) {
            // Format item facets and shorten rootLookUid
            response.data.looks.looks.forEach((look) => {
              look.items.forEach((item, index) => {
                if (look.pinnedItemUid == item.uid) {
                  item.isPinned = true;
                }
                this[index] = this.itemService.formatItem(item);
              }, look.items); // bind look.items to 'this' context
              look.shortRootLookUid = this.shortenUid(look.rootLookUid);
            });
          }
          return response.data.looks.looks;
        }),
        tap((response) => this.appStore.setState('Looks', response)),
        tap(() => this.appStore.subscribe('Looks')),
      );
    }
  }

  formatFilters(selections: { authors?: Facet[]; categories: Category[]; editors?: Facet[]; facets: Facet[] }): any {
    const filters: {
      authors: string[];
      brand: string[];
      category: string[];
      channel: string[];
      collection: string[];
      color: string[];
      editors: string[];
      end_use: string[];
      fit: string[];
      flag: string[];
      formality: string[];
      itemCount: string[];
      pattern: string[];
      processed: string[];
      membership: string[];
      sale: string[];
      size: string[];
      sport: string[];
      status: string[];
      stock: string[];
      team: string[];
      year: string[];
    } = {
      authors: [],
      brand: [],
      category: [],
      channel: [],
      collection: [],
      color: [],
      editors: [],
      end_use: [],
      fit: [],
      flag: [],
      formality: [],
      itemCount: [],
      pattern: [],
      processed: [],
      membership: [],
      sale: [],
      size: [],
      sport: [],
      status: [],
      stock: [],
      team: [],
      year: [],
    };

    // Add all selected author users to filter object
    const authors = Object.values(selections.authors);
    authors.forEach((user) => {
      filters[`${user.type}`].push(user.value);
    });

    // Add all selected editor users to filter object
    const editors = Object.values(selections.editors);
    editors.forEach((user) => {
      filters[`${user.type}`].push(user.value);
    });

    // Add all selected facets to filter object
    const facets = Object.values(selections.facets);
    facets.forEach((facet) => {
      if (facet.type === 'itemCount') {
        filters[`${facet.type}`].push(facet.value);
      } else {
        filters[`${facet.type}`].push(facet.name);
      }
    });

    // Add all selected categories to filter object
    const categories = Object.values(selections.categories);
    categories.forEach((category) => {
      filters.category.push(category.uid);
    });

    return filters;
  }
}
