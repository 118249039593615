import { Component } from '@angular/core';
import { AngularBridgeService } from '@app/core/angular-bridge-service';

@Component({
  selector: 'component-products-v4',
  templateUrl: './products-v4.component.html',
  styleUrls: ['./products-v4.component.sass'],
})
export class ProductsV4Component {
  showWarning = !!document.cookie.split(';').find((cookie) => cookie.includes('v4-url='));
  v4Url = document.cookie
    .split(';')
    .find((cookie) => cookie.includes('v4-url='))
    ?.split('=')[1];

  constructor(private angularBridge: AngularBridgeService) {}
}
